import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PDFfile from "../../static/assets/documents/QCG_White_Paper_21_April_2021.pdf"

class ThankYouPageWhitePaper extends React.Component {
  render() {
    const siteTitle = "Thank You"
    const siteDescription = this.props.data.site.siteMetadata.description
    return (
      <Layout location={this.props.location} title={`Thank You!`}>
        <Seo title={siteTitle} description={siteDescription} />
        <section className="component mt-5">
          <div className="container">
            <div className="row">
              <div className="mx-auto col-md-8 col-sm-10 text-center mt-5">
                <h3>Thank you! You may download your copy below.</h3>
                <a href={PDFfile} className="btn btn-primary mt-3">
                  Download Now
                </a>
                <div className="d-flex mt-2 justify-content-center">
                  <Link
                    aria-label="link"
                    to="/"
                    className="btn btn-primary mt-3"
                  >
                    RETURN HOME
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default ThankYouPageWhitePaper

export const ThankYouPageWhitePaperQuery = graphql`
  query ThankYouPageWhitePaperQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
